import { useRef, useEffect, useState } from 'react';
import { useLenis } from '@studio-freight/react-lenis';

const ScrollToTop = ({ children, location }) => {
    const myRef = useRef({
        location: null,
    })

    const [back, setBack] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [lastPage, setLastPage] = useState(0);

    const lenis = useLenis();
    
    useEffect(() => {
        window.onpopstate = e => {
            if ( e ) {
                console.log("back");
                setBack(true);
            }
        }
        // set the location on initial load
        if (!myRef.current.location) myRef.current.location = location
        // then make sure dialog is closed on route change
        else if (myRef.current.location !== location) {
            setTimeout( function() {
                if ( back ) {
                } else {
                    lenis.scrollTo(0);
                }
                
                setBack(false);
            }, 1000);

            // setLastPage(location.pathname);
            
            // console.log(myRef, location, lenis);

            myRef.current.location = location
        }
    },[location])

    useEffect(() => {
        // console.log(lenis);
    }, [])

    return (
        children
    )
    // <>
    //   <Header location={location} />
    //   <main>
    //     {children}
    //   </main>
    //   <Footer />
    // </>
}

export default ScrollToTop

// class ScrollToTop extends Component {
//     componentDidUpdate(prevProps) {
//         if (this.props.location !== prevProps.location) {
//             // setTimeout( function() {
//                 // alert("test");
//                 window.scrollTo(0, 0);
//             // }, 3000);
//             // console.log("Test");
//             // console.log(prevProps.location);
//         }
//     }
  
//     render() {
//         return this.props.children
//     }
// }
  
// export default ScrollToTop;