import React, { Component, useRef } from 'react';

import { withCookies } from 'react-cookie';
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import VerdalaLogo from '../../assets/svgs/Verdala-Loading-Logo.svg'

// import MouseFollow from '../mouseFollowComponent/mouseFollow';
gsap.registerPlugin(SplitText);

const LoadingScreen = (props, ref) => {
	return(
		<div ref={ref} className={ "backlay " + ((props.active) ? "animate-logo " : "hide") }>
            <div className="loading-container ">
                <div className="loading-wrapper">
                    <div className="loading">
                        <VerdalaLogo
                            ref = {ref}
                        />
                    </div>

                    <div className="loading-text">
                        <h1>A NEW PARADIGM FOR LUXURY LIVING</h1>
                    </div>
                </div>
            </div>
        </div>
	);
}

class Loading extends Component {
    constructor(props) {
        super(props);

        const { cookies } = props;

        this.loadingScreen      = React.createRef();
        // this.loadingAnimation   = this.loadingAnimation.bind(this);

        this.state = {
            loadingVisible: cookies.get('loadingVisible') || false,
            active: false,
            loadingScreen: false
        };

    }

    componentDidMount() {
        
        this.setState({
            active: true
        })

        if ( this.state.loadingVisible ) {
            this.setState({
                loadingScreen: false
            })

            setTimeout(() => {
                gsap.to(".loading-container", {
                    opacity: 0,
                    y: "-100%",
                    duration: 1,
                    ease: "Cubic.easeOut",
                    onComplete: function() {
                        gsap.set(".loading-container", {display: "none"});
                    }
                })
            }, 500)

        } else {
            this.setState({
                loadingScreen: true
            })
            
            // console.log("Show Load");
            this.props.cookies.set('loadingVisible', true, { path: '/' });

            let split = SplitText.create(".loading-text h1", {type:"words"});

            gsap.set(
                ".loading-container",
                {
                    transformOrigin:"50% 0%"
                }
            );
    
            gsap
            .timeline()
            .set('.loading-container .loading-text h1', { opacity: 1 })
            .set('.loading svg', { autoAlpha: 0, y:0 })
            .set(split.words, { autoAlpha: 0, y:20 })
            .set('.loading-text', { opaciy: 1 })
            .to('.loading svg', {
                autoAlpha: 1,
                y:0,
                duration: 1.5,
            })
            .to('.loading', {
                "--stat": '100%',
                duration: 3,
                ease: 'expo.inOut'
            })
            .to('.loading', {
                y: 0,
                autoAlpha: 0,
                duration: 1,
                ease: 'expo.inOut'
            })
            .to('.loading svg', {
                autoAlpha: 0,
                y: 0,
                duration: 1
            }, '-=1.2')
            .to(split.words, {
                autoAlpha: 1,
                y: 0,
                stagger: 0.1,
                duration: 1.7,
                ease: 'expo.out'
            }, '-=0.5')
            .to(split.words, {
                autoAlpha: 0,
                // y: -100,
                stagger: 0.1,
                duration: 2,
                ease: 'expo.inOut'
            }, '-=0.5')
            .to(".loading-container", {
                opacity: 0,
                y: "-100%",
                duration: 1,
                ease: "Cubic.easeOut",
                onComplete: function() {
                    gsap.set(".loading-container", {display: "none"});
                }
            }, '-=1')
            

            setTimeout(() => {
                gsap
                .timeline()
                .set(".home-banner-sec .bg-img", { scale: 1.3 })
                .set(".anim-scroll-up-home,.customarrow", { opacity: 0, y: 100 })
                .set(".title-cont *", { opacity: 0, y: 200 })
                .to('.home-banner-sec .bg-img',
                    {
                        scale: 1,
                        duration: 3,
                        ease: "Cubic.easeOut"
                    }
                )
                .to(
                    ".anim-scroll-up-home,.customarrow",
                    {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        stagger: {
                            amount: 0.5,
                            from: "random"
                        },
                        ease: "Cubic.easeOut"
                    }, '-=1.5'
                )
                .to(
                    ".title-cont *",
                    {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        stagger: 0.3,
                        // delay: 1.5,
                        ease: "Cubic.easeOut"
                    }, '-=1.0'
                )
            }, 8000);
        }
    }

    render() {
        // var addClass = (this.state.active) ? "animate-logo " : "";

        // var class = "hide";

        let loading;

        if ( this.state.loadingScreen ) {
            loading = LoadingScreen( this.state, this.loadingScreen );
        } else {
            loading = LoadingScreen( this.state, this.loadingScreen );
            // loading = null;
        }

        return (
            loading 
        );
        
    }
}

export default withCookies(Loading);