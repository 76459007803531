import React, { useState, useEffect } from "react";
import { MDXProvider } from "@mdx-js/react";
import Header from "../header/header";
import Loading from "../loading/loading";

// import { allMenu } from "../../utils/api";

const Layout = (props) => {
  const [scroll, setScroll] = useState(false);
  // const [menuData, setMenuData] = useState([]);
  // const [data, setData] = useState([]);
  
  // useEffect(() => {
  //   const func = async () => {
  //     try {
  //       const res = await allMenu();
  //       setData(res.acf.add_menu);
  //     } catch (error) {
  //       console.log("ERROR DURING GET MENU DATA");
  //     }
  //   };
  //   func();
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <div className="global-wrapper">
      <Loading
        loadState = {(props !== undefined) ? true : false}
      />

      <header className={scroll ? "global-header fixed" : "global-header"}>
        <Header
          {...props}
        />
      </header>

      <MDXProvider>
        <main>{props.children}</main>
      </MDXProvider>
    </div>
  );
};

export { Layout };
