import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import { gsap } from "gsap";
import TransitionLink, {
  TransitionPortal,
} from "gatsby-plugin-transition-link";
import Searchicn from "../../assets/svgs/search.svg";
import * as headerStyles from "./header.module.scss";
import VerdalaLogo from "../../assets/svgs/verdala-logo.svg";
import VerdalaLogoalt from "../../assets/svgs/verdala-logo-alt.svg";
import Container from "../layouts/container";
import MenuImage from "../../assets/images/test-images/menu-image.jpg";
// import HoverHeaderImage from "../headerImage";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import arrow from "../../images/arrow.svg";
import pluse from "../../images/pluse.svg";
// import { Lenis } from "@studio-freight/react-lenis";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      headerActive: { index: 0, page: "Vision" },
      subMenuState: false,
      darkHeader: false,
    };

    this.toggleClass = this.toggleClass.bind(this);
    this.verticalAnimation = this.verticalAnimation.bind(this);
    this.darkHeaderCheck = this.darkHeaderCheck.bind(this);

    this.layoutContents = React.createRef();
    this.transitionCover = React.createRef();
  }

  toggleClass() {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 1201) {
        const currentState = this.state.active;

        this.setState({
          active: !currentState
        });
      }
    }
  }

  verticalAnimation = ({ length }, direction) => {
    const directionTo = direction === "up" ? "-100%" : "100%";
    const directionFrom = direction === "up" ? "100%" : "-100%";

    // convert ms to s for gsap
    const seconds = length;

    return gsap
      .timeline()
      .set(this.transitionCover, { y: directionFrom })
      .to(this.transitionCover, {
        y: "0%",
        ease: "power1.easeInOut",
        duration: seconds,
      })
      .to(this.transitionCover, {
        y: directionTo,
        ease: "power1.easeIn",
        duration: seconds,
      });
  };

  darkHeaderCheck() {
    let darkState = false;

    const darkHeader = [
      "/contact/",
      "/media-centre/",
      "/building/",
      "/development-progress/",
      "/property/",
      "/property-model/",
      "/terms-and-conditions/",
      "/privacy-policy/",
    ];

    darkHeader.map((link, key) => {
      if (this.props.location.pathname.includes(link)) {
        darkState = true;
      }
    });

    this.setState({
      darkHeader: darkState,
    });

    return darkState;
  }

  componentDidMount() {
    // console.log(this.props.data);
    // console.log(this.transitionCover);
    this.darkHeaderCheck();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.darkHeaderCheck();
    }
  }

  render() {
    return (
      <>
        <Container>
          <div
            className={
              "header_inner " +
              ((this.state.subMenuState || (this.state.active && window.innerWidth < 821)) ? "sub-menu-active" : "") +
              ((this.state.darkHeader && !this.state.subMenuState && !this.state.active)
                ? " dark-menu-state"
                : "")
            }
          >
            <TransitionLink
              to={"/"}
              exit={{
                length: 1,
                trigger: ({ exit }) => this.verticalAnimation(exit, "up"),
                state: { test: "exit state" },
              }}
              entry={{
                delay: 0,
                trigger: () => this.setState({ active: false }),
                state: { test: "exit state" },
              }}
              className={
                this.state.darkHeader && !this.state.subMenuState
                  ? headerStyles.darkActive
                  : ""
              }
            >
              <div
                className={headerStyles.logoIcon + " " + headerStyles.darkIcon}
              >
                <VerdalaLogoalt />
              </div>
              <div className={headerStyles.logoIcon}>
                <VerdalaLogo />
              </div>
            </TransitionLink>

            <nav
              ref={(n) => (this.layoutContents = n)}
              className={this.state.active ? "active" : null}
            >
              <ul>
                {/* <span className="closebtn" onClick={this.toggleClass}>
                  X
                </span> */}
                <StaticQuery
                  query={graphql`
                    query {
                      site {
                        siteMetadata {
                          title
                          menuLinks {
                            name
                            link
                          }
                        }
                      }
                      wpMenuItems(slug: { eq: "main-menu" }) {
                        slug
                        title
                        generalOptionsMain {
                          addMenuSettings {
                            menuItem {
                              title
                              url
                            }
                            subMenu {
                              subMenuItem {
                                title
                                url
                              }
                              menuImage {
                                sourceUrl
                                altText
                              }
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={(data) => {
                    return (
                      <>
                        {data.wpMenuItems.generalOptionsMain.addMenuSettings.map(
                          (item, key) => {
                            return (
                              <li
                                key={key}
                                className={
                                  "display-menu " +
                                  (item.subMenu ? "has-sub-menu " : "") +
                                  ((this.props.location.pathname === item.menuItem.url + "/") ? "active-page " : "")
                                }
                                onMouseOver={() =>
                                  item.subMenu &&
                                  this.setState({ subMenuState: true })
                                }
                                onMouseOut={() =>
                                  item.subMenu &&
                                  this.setState({ subMenuState: false })
                                }
                              >
                                <TransitionLink
                                  to={item.menuItem.url}
                                  exit={{
                                    length: 1,
                                    trigger: ({ exit }) =>
                                      this.verticalAnimation(exit, "up"),
                                    state: { test: "exit state" },
                                  }}
                                  entry={{
                                    delay: 0,
                                    trigger: ({ entry, node }) => {
                                      this.toggleClass();
                                    },
                                    state: { test: "exit state" },
                                  }}
                                  style={{
                                    textDecoration:
                                      this.props.location.pathname ==
                                      `${item.menuItem.link}/`
                                        ? "underline"
                                        : "",
                                    color:
                                      this.props.location.pathname.includes(
                                        "/contact/"
                                      ) ||
                                      this.props.location.pathname.includes(
                                        "/media-centre/"
                                      ) ||
                                      this.props.location.pathname.includes(
                                        "/building/"
                                      ) ||
                                      this.props.location.pathname.includes(
                                        "/development-progress/"
                                      ) ||
                                      this.props.location.pathname.includes(
                                        "/property/"
                                      ) ||
                                      this.props.location.pathname.includes(
                                        "/terms-and-conditions/"
                                      ) ||
                                      this.props.location.pathname.includes(
                                        "/privacy-policy/"
                                      )
                                        ? "#635245"
                                        : "#FFFFFF",
                                  }}
                                >
                                  <span dangerouslySetInnerHTML={{__html: item.menuItem.title}}></span>

                                  {item.subMenu && (
                                    <div className="menu-arrow">
                                      <svg
                                        width="10"
                                        height="6"
                                        viewBox="0 0 10 6"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.832031 0.916504L4.9987 5.08317L9.16536 0.916504"
                                          stroke="white"
                                          stroke-miterlimit="10"
                                          strokeLinecap="square"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </TransitionLink>
                                {item.subMenu ? (
                                  <div className="type-selector-new">
                                    <div className="type-selector-card-overlay">
                                      {item?.subMenu.map((items, index) => {
                                        return (
                                          <>
                                            <div
                                              className="type-selector-card"
                                              key={index}
                                              data-id={`${items?.subMenuItem?.url}`}
                                            >

                                              {( items?.subMenuItem?.url.includes("#") && this.props.location.pathname === item.menuItem.url + "/" ) ?
                                                <div 
                                                onClick={ () => {
                                                  setTimeout(() => { 
                                                    console.log("test click", ".on-page-nav-item[data-id='" + items?.subMenuItem?.url.split("#")[1] + "']");
                                                    document.querySelector(".on-page-nav-item[data-id='" + items?.subMenuItem?.url.split("#")[1].toLowerCase() + "']").click();
                                                  }, 100);
                                                  
                                                  return false;
                                                }}
                                                >
                                                  <div className="type-card-img">
                                                    <img
                                                      src={
                                                        items?.menuImage?.sourceUrl
                                                      }
                                                      alt={
                                                        items?.menuImage?.altText
                                                      }
                                                    />
                                                  </div>

                                                  <div>
                                                    <h5
                                                      onClick={() =>
                                                        this.setState({
                                                          headerActive: {
                                                            index: index,
                                                            page: item.menuItem
                                                              .title,
                                                          },
                                                        })
                                                      }
                                                    >
                                                      {items?.subMenuItem?.title}{" "}
                                                      <img src={pluse} />
                                                    </h5>
                                                  </div>
                                                </div>
                                              :
                                                <TransitionLink
                                                  to={`${items?.subMenuItem?.url}`}
                                                  
                                                  exit={{
                                                    length: 1.5,
                                                    trigger: ({ exit }) =>
                                                      this.verticalAnimation(
                                                        exit,
                                                        "up"
                                                      ),
                                                  }}
                                                  entry={{
                                                    delay: 0.5,
                                                    trigger: ({ entry, node }) => {
                                                      this.toggleClass();
                                                    },
                                                    state: {
                                                      pageScroll:
                                                        items?.subMenuItem?.url.split(
                                                          "#"
                                                        )[1],
                                                    },
                                                  }}
                                                >
                                                  <div className="type-card-img">
                                                    <img
                                                      src={
                                                        items?.menuImage?.sourceUrl
                                                      }
                                                      alt={
                                                        items?.menuImage?.altText
                                                      }
                                                    />
                                                  </div>

                                                  <div>
                                                    <h5
                                                      onClick={() =>
                                                        this.setState({
                                                          headerActive: {
                                                            index: index,
                                                            page: item.menuItem
                                                              .title,
                                                          },
                                                        })
                                                      }
                                                    >
                                                      {items?.subMenuItem?.title}{" "}
                                                      <img src={pluse} />
                                                    </h5>
                                                  </div>
                                                </TransitionLink>
                                              }
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : null}
                              </li>
                            );
                          }
                        )}
                      </>
                    );
                  }}
                />
                <div className="selector-title">
                  <span>Search Residences:</span>
                </div>
                <div className="type-selector">
                  <TransitionLink
                    to={"/building"}
                    exit={{
                      length: 1.5,
                      trigger: ({ exit }) => this.verticalAnimation(exit, "up"),
                      state: { test: "exit state" },
                    }}
                    entry={{
                      delay: 0.5,
                      trigger: ({ entry, node }) => {
                        this.setState({ 
                          active: false
                        });
                      },
                    }}

                    // style={backgroundImage: "url"}
                  >
                    <div
                      className="type"
                      style={{ backgroundImage: `url(${MenuImage})` }}
                    >
                      <span>Visual Search by Mansions</span>
                    </div>
                  </TransitionLink>

                  <TransitionLink
                    to={"/apartments"}
                    exit={{
                      length: 1.5,
                      trigger: ({ exit }) => this.verticalAnimation(exit, "up"),
                      state: { test: "exit state" },
                    }}
                    entry={{
                      delay: 0.5,
                      trigger: ({ entry, node }) => {
                        this.setState({ 
                          active: false
                        });
                      },
                    }}
                  >
                    <div className="type">
                      <span>Search by Features</span>
                    </div>
                  </TransitionLink>
                </div>
              </ul>

            </nav>
            <div className="headerbtns">
              <div className={`button ${headerStyles.searchbtnwrap}`}>
                <div className="inner-button search-btn">
                  <Searchicn />
                  <span className={headerStyles.searchtxt}>
                    Search Residence
                  </span>
                </div>

                <div className="type-selector">
                  <TransitionLink
                    to={"/building"}
                    exit={{
                      length: 1.5,
                      trigger: ({ exit }) => this.verticalAnimation(exit, "up"),
                      state: { test: "exit state" },
                    }}
                    entry={{
                      delay: 0.5,
                      trigger: ({ entry, node }) => {
                        this.setState({ 
                          active: false
                        });
                      },
                    }}

                    // style={backgroundImage: "url"}
                  >
                    <div
                      className="type"
                      style={{ backgroundImage: `url(${MenuImage})` }}
                    >
                      <span>Visual Search by Mansions</span>
                    </div>
                  </TransitionLink>

                  <TransitionLink
                    to={"/apartments"}
                    exit={{
                      length: 1.5,
                      trigger: ({ exit }) => this.verticalAnimation(exit, "up"),
                      state: { test: "exit state" },
                    }}
                    entry={{
                      delay: 0.5,
                      trigger: ({ entry, node }) => {
                        this.setState({ 
                          active: false
                        });
                      },
                    }}
                  >
                    <div className="type">
                      <span>Search by Features</span>
                    </div>
                  </TransitionLink>
                </div>
              </div>
              <div
                className={headerStyles.menu_togglebtn}
                onClick={this.toggleClass}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </Container>

        <TransitionPortal>
          <div
            className="test-cover"
            ref={(n) => (this.transitionCover = n)}
            style={{
              position: "fixed",
              background: "#7B9E6B",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              transform: "translateY(100%)",
              zIndex: 999,
            }}
          />
        </TransitionPortal>
      </>
    );
  }
}

export default Header;
