exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartments-js": () => import("./../../../src/pages/apartments.js" /* webpackChunkName: "component---src-pages-apartments-js" */),
  "component---src-pages-ax-story-js": () => import("./../../../src/pages/ax-story.js" /* webpackChunkName: "component---src-pages-ax-story-js" */),
  "component---src-pages-axstory-index-js": () => import("./../../../src/pages/Axstory/index.js" /* webpackChunkName: "component---src-pages-axstory-index-js" */),
  "component---src-pages-building-index-js": () => import("./../../../src/pages/building/index.js" /* webpackChunkName: "component---src-pages-building-index-js" */),
  "component---src-pages-building-property-model-grand-js": () => import("./../../../src/pages/building/property-model/grand.js" /* webpackChunkName: "component---src-pages-building-property-model-grand-js" */),
  "component---src-pages-building-property-model-js": () => import("./../../../src/pages/building/property-model.js" /* webpackChunkName: "component---src-pages-building-property-model-js" */),
  "component---src-pages-building-property-model-royal-js": () => import("./../../../src/pages/building/property-model/royal.js" /* webpackChunkName: "component---src-pages-building-property-model-royal-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-day-in-life-js": () => import("./../../../src/pages/day-in-life.js" /* webpackChunkName: "component---src-pages-day-in-life-js" */),
  "component---src-pages-day-life-dayinlife-mob-js": () => import("./../../../src/pages/DayLife/dayinlifeMob.js" /* webpackChunkName: "component---src-pages-day-life-dayinlife-mob-js" */),
  "component---src-pages-day-life-index-js": () => import("./../../../src/pages/DayLife/index.js" /* webpackChunkName: "component---src-pages-day-life-index-js" */),
  "component---src-pages-development-progress-index-js": () => import("./../../../src/pages/DevelopmentProgress/index.js" /* webpackChunkName: "component---src-pages-development-progress-index-js" */),
  "component---src-pages-development-progress-js": () => import("./../../../src/pages/development-progress.js" /* webpackChunkName: "component---src-pages-development-progress-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/Gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-l-ifestyles-a-communal-destination-index-js": () => import("./../../../src/pages/LIfestyles/ACommunalDestination/index.js" /* webpackChunkName: "component---src-pages-l-ifestyles-a-communal-destination-index-js" */),
  "component---src-pages-l-ifestyles-a-day-in-the-life-index-js": () => import("./../../../src/pages/LIfestyles/ADayInTheLife/index.js" /* webpackChunkName: "component---src-pages-l-ifestyles-a-day-in-the-life-index-js" */),
  "component---src-pages-l-ifestyles-amenities-index-js": () => import("./../../../src/pages/LIfestyles/Amenities/index.js" /* webpackChunkName: "component---src-pages-l-ifestyles-amenities-index-js" */),
  "component---src-pages-l-ifestyles-lifestyle-index-js": () => import("./../../../src/pages/LIfestyles/Lifestyle/index.js" /* webpackChunkName: "component---src-pages-l-ifestyles-lifestyle-index-js" */),
  "component---src-pages-l-ifestyles-lifestyle-smallscreen-lifestyle-index-js": () => import("./../../../src/pages/LIfestyles/Lifestyle/smallscreenLifestyle/index.js" /* webpackChunkName: "component---src-pages-l-ifestyles-lifestyle-smallscreen-lifestyle-index-js" */),
  "component---src-pages-lifestyle-amenities-js": () => import("./../../../src/pages/lifestyle-amenities.js" /* webpackChunkName: "component---src-pages-lifestyle-amenities-js" */),
  "component---src-pages-media-centre-js": () => import("./../../../src/pages/media-centre.js" /* webpackChunkName: "component---src-pages-media-centre-js" */),
  "component---src-pages-neighbourhood-connectivity-index-js": () => import("./../../../src/pages/Neighbourhood/Connectivity/index.js" /* webpackChunkName: "component---src-pages-neighbourhood-connectivity-index-js" */),
  "component---src-pages-neighbourhood-js": () => import("./../../../src/pages/neighbourhood.js" /* webpackChunkName: "component---src-pages-neighbourhood-js" */),
  "component---src-pages-neighbourhood-mapping-index-js": () => import("./../../../src/pages/Neighbourhood/Mapping/index.js" /* webpackChunkName: "component---src-pages-neighbourhood-mapping-index-js" */),
  "component---src-pages-neighbourhood-rabat-and-mdina-index-js": () => import("./../../../src/pages/Neighbourhood/RabatAndMdina/index.js" /* webpackChunkName: "component---src-pages-neighbourhood-rabat-and-mdina-index-js" */),
  "component---src-pages-neighbourhood-things-to-do-index-js": () => import("./../../../src/pages/Neighbourhood/ThingsToDo/index.js" /* webpackChunkName: "component---src-pages-neighbourhood-things-to-do-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-residences-about-residences-index-js": () => import("./../../../src/pages/Residences/AboutResidences/index.js" /* webpackChunkName: "component---src-pages-residences-about-residences-index-js" */),
  "component---src-pages-residences-design-meets-function-index-js": () => import("./../../../src/pages/Residences/DesignMeetsFunction/index.js" /* webpackChunkName: "component---src-pages-residences-design-meets-function-index-js" */),
  "component---src-pages-residences-mansions-index-js": () => import("./../../../src/pages/Residences/Mansions/index.js" /* webpackChunkName: "component---src-pages-residences-mansions-index-js" */),
  "component---src-pages-residences-type-of-residences-index-js": () => import("./../../../src/pages/Residences/TypeOfResidences/index.js" /* webpackChunkName: "component---src-pages-residences-type-of-residences-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-the-hotel-hotel-page-section-js": () => import("./../../../src/pages/The Hotel/hotelPageSection.js" /* webpackChunkName: "component---src-pages-the-hotel-hotel-page-section-js" */),
  "component---src-pages-the-hotel-index-js": () => import("./../../../src/pages/The Hotel/index.js" /* webpackChunkName: "component---src-pages-the-hotel-index-js" */),
  "component---src-pages-the-hotel-js": () => import("./../../../src/pages/the-hotel.js" /* webpackChunkName: "component---src-pages-the-hotel-js" */),
  "component---src-pages-the-residences-js": () => import("./../../../src/pages/the-residences.js" /* webpackChunkName: "component---src-pages-the-residences-js" */),
  "component---src-pages-view-index-js": () => import("./../../../src/pages/View/index.js" /* webpackChunkName: "component---src-pages-view-index-js" */),
  "component---src-pages-vision-architecture-index-js": () => import("./../../../src/pages/Vision/Architecture/index.js" /* webpackChunkName: "component---src-pages-vision-architecture-index-js" */),
  "component---src-pages-vision-journey-section-index-js": () => import("./../../../src/pages/Vision/JourneySection/index.js" /* webpackChunkName: "component---src-pages-vision-journey-section-index-js" */),
  "component---src-pages-vision-journey-section-small-screen-journey-index-js": () => import("./../../../src/pages/Vision/JourneySection/smallScreenJourney/index.js" /* webpackChunkName: "component---src-pages-vision-journey-section-small-screen-journey-index-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-pages-vision-team-index-js": () => import("./../../../src/pages/Vision/Team/index.js" /* webpackChunkName: "component---src-pages-vision-team-index-js" */),
  "component---src-pages-vision-team-small-screen-team-index-js": () => import("./../../../src/pages/Vision/Team/smallScreenTeam/index.js" /* webpackChunkName: "component---src-pages-vision-team-small-screen-team-index-js" */),
  "component---src-pages-vision-today-vision-index-js": () => import("./../../../src/pages/Vision/TodayVision/index.js" /* webpackChunkName: "component---src-pages-vision-today-vision-index-js" */),
  "component---src-pages-vision-verdala-legacy-index-js": () => import("./../../../src/pages/Vision/VerdalaLegacy/index.js" /* webpackChunkName: "component---src-pages-vision-verdala-legacy-index-js" */),
  "component---src-templates-news-single-news-js": () => import("./../../../src/templates/news/single-news.js" /* webpackChunkName: "component---src-templates-news-single-news-js" */),
  "component---src-templates-property-single-property-js": () => import("./../../../src/templates/property/single-property.js" /* webpackChunkName: "component---src-templates-property-single-property-js" */)
}

